.ReactModal__Html--open,
.ReactModal__Body--open {
    overflow: hidden;
    position: fixed; top: 0; right: 0; bottom: 0; left: 0;
    /*width: 100%; height: 100%;*/
}

.ReactModal__Overlay {
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    /*height: 100%;*/
    /*width: 100%;*/
    /*width: 100vw;*/
    /*height: 100vh;*/
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}

.ReactModal__Content {
    background: white;
    width: 768px;
    max-width: calc(100vw );
    max-height: calc(100vh );
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;
    padding: 12px;
    outline: none;
    height: calc(100% + 1px);
    -webkit-overflow-scrolling: touch
}
